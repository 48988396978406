/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatMessageDtoFromJSON, ChatMessageDtoToJSON, } from './ChatMessageDto.js';
/**
 * Check if a given object implements the GetChatMessagesResult interface.
 */
export function instanceOfGetChatMessagesResult(value) {
    if (!('matchId' in value) || value['matchId'] === undefined)
        return false;
    if (!('messages' in value) || value['messages'] === undefined)
        return false;
    return true;
}
export function GetChatMessagesResultFromJSON(json) {
    return GetChatMessagesResultFromJSONTyped(json, false);
}
export function GetChatMessagesResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'matchId': json['matchId'],
        'messages': (json['messages'].map(ChatMessageDtoFromJSON)),
    };
}
export function GetChatMessagesResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'matchId': value['matchId'],
        'messages': (value['messages'].map(ChatMessageDtoToJSON)),
    };
}
