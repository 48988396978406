import type { ResponseError } from '@swipe4work/api-client-fetch'
import { Configuration } from '@swipe4work/api-client-fetch'
import { getAccessToken } from '@/store/auth'

export const fetchConfiguration = new Configuration({
  basePath: import.meta.env.VITE_APP_API_URL,
  middleware: [
    {
      async pre(context) {
        const accessToken = await getAccessToken()

        if (!accessToken) {
          return context
        }

        return {
          url: context.url,
          init: {
            ...context.init,
            headers: new Headers({
              ...context.init.headers,
              Authorization: `Bearer ${accessToken}`,
            }),
          },
        }
      },
      onError(context) {
        return Promise.reject(context.error)
      },
    },
  ],
})

export async function hasErrorCode(err: ResponseError, errorCode: number): Promise<boolean> {
  // Parse the HTTP response and check if the errorMap has the given error code
  const errors = (await err.response.json()).errors
  if (!errors) {
    return false
  }

  const hasErrorCode = Object.prototype.hasOwnProperty.call(errors, errorCode)
  return hasErrorCode
}
