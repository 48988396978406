import { PermissionLevel, Role } from '@swipe4work/api-client-fetch'
import { useAuthStore } from '@/store'
import type { UserModule } from '@/types'
import { useUserStore } from '@/store/user-store'

export const install: UserModule = (_, router) => {
  // Route guards
  router.beforeEach(async (to) => {
    const authStore = useAuthStore()
    if (authStore.isMsalUser()) {
      await authStore.initForMsal()
    }

    const isAuthenticated = await authStore.isAuthenticated()
    if (!isAuthenticated) {
      if (!to.meta.allowAnonymous) {
        return {
          name: '/login',
        }
      }
      return
    }

    await authStore.getRole()
    // None should not happen, since an employer is either invited by email (and the user becomes an onboarding Employer),
    // Or the user opened the app and a User account is created.
    if (authStore.isEmployeeUser || authStore.role === Role.None) {
      // Not allowed to access the application as a normal user
      if (to.name !== '/employee') {
        return {
          name: '/employee',
        }
      }
      return
    }
    if (!to.meta.isOnboarding && authStore.role === Role.OnboardingEmployer) {
      return {
        name: '/onboarding/company',
      }
    }
    if (to.meta.isOnboarding && authStore.role === Role.Employer) {
      return {
        name: '/',
      }
    }
    if (to.name === '/employee') {
      return {
        name: '/',
      }
    }

    // Verify the required permissions with the user's permissions
    if (to.meta.permissionResource !== undefined) {
      const userStore = useUserStore()
      if (!userStore.profile && authStore.role === Role.Employer) {
        await userStore.fetchProfile()
      }

      const permissionLevel = to.meta.permissionLevel ?? PermissionLevel.Read
      if (permissionLevel === PermissionLevel.Read && !userStore.hasReadPermission(to.meta.permissionResource)) {
        console.warn(`User does not have permission to access ${to.meta.permissionResource}`)
        return {
          name: '/',
        }
      }
      if (permissionLevel === PermissionLevel.Write && !userStore.hasWritePermission(to.meta.permissionResource)) {
        console.warn(`User does not have permission to access ${to.meta.permissionResource}`)
        return {
          name: '/',
        }
      }
    }
  })
}
