/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillingPeriodicityTypeFromJSON, BillingPeriodicityTypeToJSON, } from './BillingPeriodicityType.js';
/**
 * Check if a given object implements the BillingCycleType interface.
 */
export function instanceOfBillingCycleType(value) {
    if (!('periodicity' in value) || value['periodicity'] === undefined)
        return false;
    if (!('daysInAdvance' in value) || value['daysInAdvance'] === undefined)
        return false;
    return true;
}
export function BillingCycleTypeFromJSON(json) {
    return BillingCycleTypeFromJSONTyped(json, false);
}
export function BillingCycleTypeFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'periodicity': BillingPeriodicityTypeFromJSON(json['periodicity']),
        'daysInAdvance': json['days_in_advance'],
    };
}
export function BillingCycleTypeToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'periodicity': BillingPeriodicityTypeToJSON(value['periodicity']),
    };
}
