/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TotalSpecFromJSON, TotalSpecToJSON, } from './TotalSpec.js';
import { UnitPriceSpecFromJSON, UnitPriceSpecToJSON, } from './UnitPriceSpec.js';
/**
 * Check if a given object implements the SubscriptionLineItem interface.
 */
export function instanceOfSubscriptionLineItem(value) {
    if (!('quantity' in value) || value['quantity'] === undefined)
        return false;
    if (!('description' in value) || value['description'] === undefined)
        return false;
    if (!('unitPrice' in value) || value['unitPrice'] === undefined)
        return false;
    return true;
}
export function SubscriptionLineItemFromJSON(json) {
    return SubscriptionLineItemFromJSONTyped(json, false);
}
export function SubscriptionLineItemFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'quantity': json['quantity'],
        'description': json['description'],
        'unitPrice': UnitPriceSpecFromJSON(json['unit_price']),
        'total': json['total'] == null ? undefined : TotalSpecFromJSON(json['total']),
    };
}
export function SubscriptionLineItemToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'quantity': value['quantity'],
        'description': value['description'],
        'unit_price': UnitPriceSpecToJSON(value['unitPrice']),
        'total': TotalSpecToJSON(value['total']),
    };
}
