/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobCategoryCrudJobCategoryDtoFromJSON, JobCategoryCrudJobCategoryDtoToJSON, } from './JobCategoryCrudJobCategoryDto.js';
/**
 * Check if a given object implements the JobCategoryCrudListResult interface.
 */
export function instanceOfJobCategoryCrudListResult(value) {
    if (!('jobCategories' in value) || value['jobCategories'] === undefined)
        return false;
    return true;
}
export function JobCategoryCrudListResultFromJSON(json) {
    return JobCategoryCrudListResultFromJSONTyped(json, false);
}
export function JobCategoryCrudListResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'jobCategories': (json['jobCategories'].map(JobCategoryCrudJobCategoryDtoFromJSON)),
    };
}
export function JobCategoryCrudListResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'jobCategories': (value['jobCategories'].map(JobCategoryCrudJobCategoryDtoToJSON)),
    };
}
