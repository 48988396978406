/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ImageDtoFromJSON, ImageDtoToJSON, } from './ImageDto.js';
/**
 * Check if a given object implements the SortedImageDto interface.
 */
export function instanceOfSortedImageDto(value) {
    if (!('image' in value) || value['image'] === undefined)
        return false;
    return true;
}
export function SortedImageDtoFromJSON(json) {
    return SortedImageDtoFromJSONTyped(json, false);
}
export function SortedImageDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'image': ImageDtoFromJSON(json['image']),
        'order': json['order'] == null ? undefined : json['order'],
    };
}
export function SortedImageDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'image': ImageDtoToJSON(value['image']),
        'order': value['order'],
    };
}
