/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListReferralInvitesReferralInviteDtoFromJSON, ListReferralInvitesReferralInviteDtoToJSON, } from './ListReferralInvitesReferralInviteDto.js';
/**
 * Check if a given object implements the ListReferralInvitesResult interface.
 */
export function instanceOfListReferralInvitesResult(value) {
    if (!('invites' in value) || value['invites'] === undefined)
        return false;
    return true;
}
export function ListReferralInvitesResultFromJSON(json) {
    return ListReferralInvitesResultFromJSONTyped(json, false);
}
export function ListReferralInvitesResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'invites': (json['invites'].map(ListReferralInvitesReferralInviteDtoFromJSON)),
    };
}
export function ListReferralInvitesResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'invites': (value['invites'].map(ListReferralInvitesReferralInviteDtoToJSON)),
    };
}
