/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ListReferralInvitesReferralInviteDto interface.
 */
export function instanceOfListReferralInvitesReferralInviteDto(value) {
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('isCompleted' in value) || value['isCompleted'] === undefined)
        return false;
    return true;
}
export function ListReferralInvitesReferralInviteDtoFromJSON(json) {
    return ListReferralInvitesReferralInviteDtoFromJSONTyped(json, false);
}
export function ListReferralInvitesReferralInviteDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'email': json['email'],
        'createdAt': (new Date(json['createdAt'])),
        'isCompleted': json['isCompleted'],
    };
}
export function ListReferralInvitesReferralInviteDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'email': value['email'],
        'createdAt': ((value['createdAt']).toISOString()),
        'isCompleted': value['isCompleted'],
    };
}
