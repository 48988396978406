/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SendPollCommandDto interface.
 */
export function instanceOfSendPollCommandDto(value) {
    if (!('matchId' in value) || value['matchId'] === undefined)
        return false;
    if (!('messageBody' in value) || value['messageBody'] === undefined)
        return false;
    if (!('interviewDates' in value) || value['interviewDates'] === undefined)
        return false;
    return true;
}
export function SendPollCommandDtoFromJSON(json) {
    return SendPollCommandDtoFromJSONTyped(json, false);
}
export function SendPollCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'matchId': json['matchId'],
        'messageBody': json['messageBody'],
        'interviewDates': json['interviewDates'],
    };
}
export function SendPollCommandDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'matchId': value['matchId'],
        'messageBody': value['messageBody'],
        'interviewDates': value['interviewDates'],
    };
}
