/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListCreditTransactionsCreditTransactionDtoFromJSON, ListCreditTransactionsCreditTransactionDtoToJSON, } from './ListCreditTransactionsCreditTransactionDto.js';
/**
 * Check if a given object implements the ListCreditTransactionsResult interface.
 */
export function instanceOfListCreditTransactionsResult(value) {
    if (!('creditTotal' in value) || value['creditTotal'] === undefined)
        return false;
    if (!('creditTransactions' in value) || value['creditTransactions'] === undefined)
        return false;
    return true;
}
export function ListCreditTransactionsResultFromJSON(json) {
    return ListCreditTransactionsResultFromJSONTyped(json, false);
}
export function ListCreditTransactionsResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'creditTotal': json['creditTotal'],
        'creditTransactions': (json['creditTransactions'].map(ListCreditTransactionsCreditTransactionDtoFromJSON)),
    };
}
export function ListCreditTransactionsResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'creditTotal': value['creditTotal'],
        'creditTransactions': (value['creditTransactions'].map(ListCreditTransactionsCreditTransactionDtoToJSON)),
    };
}
