/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DefaultMessageTypeFromJSON, DefaultMessageTypeToJSON, } from './DefaultMessageType.js';
/**
 * Check if a given object implements the AddOrUpdateDefaultMessageCommandDto interface.
 */
export function instanceOfAddOrUpdateDefaultMessageCommandDto(value) {
    if (!('messageType' in value) || value['messageType'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('body' in value) || value['body'] === undefined)
        return false;
    return true;
}
export function AddOrUpdateDefaultMessageCommandDtoFromJSON(json) {
    return AddOrUpdateDefaultMessageCommandDtoFromJSONTyped(json, false);
}
export function AddOrUpdateDefaultMessageCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'messageType': DefaultMessageTypeFromJSON(json['messageType']),
        'title': json['title'],
        'body': json['body'],
    };
}
export function AddOrUpdateDefaultMessageCommandDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'messageType': DefaultMessageTypeToJSON(value['messageType']),
        'title': value['title'],
        'body': value['body'],
    };
}
