/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the NullableRangeDto interface.
 */
export function instanceOfNullableRangeDto(value) {
    if (!('lower' in value) || value['lower'] === undefined)
        return false;
    return true;
}
export function NullableRangeDtoFromJSON(json) {
    return NullableRangeDtoFromJSONTyped(json, false);
}
export function NullableRangeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'lower': json['lower'],
        'upper': json['upper'] == null ? undefined : json['upper'],
    };
}
export function NullableRangeDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'lower': value['lower'],
        'upper': value['upper'],
    };
}
