/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobStatusFromJSON, JobStatusToJSON, } from './JobStatus.js';
import { JobClosureReasonFromJSON, JobClosureReasonToJSON, } from './JobClosureReason.js';
import { JobDescriptionDtoFromJSON, JobDescriptionDtoToJSON, } from './JobDescriptionDto.js';
/**
 * Check if a given object implements the ListJobsJobDto interface.
 */
export function instanceOfListJobsJobDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('description' in value) || value['description'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('locationName' in value) || value['locationName'] === undefined)
        return false;
    if (!('jobCategoryId' in value) || value['jobCategoryId'] === undefined)
        return false;
    if (!('jobFunctionId' in value) || value['jobFunctionId'] === undefined)
        return false;
    if (!('waitingJobApplicationCount' in value) || value['waitingJobApplicationCount'] === undefined)
        return false;
    return true;
}
export function ListJobsJobDtoFromJSON(json) {
    return ListJobsJobDtoFromJSONTyped(json, false);
}
export function ListJobsJobDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'description': JobDescriptionDtoFromJSON(json['description']),
        'createdAt': (new Date(json['createdAt'])),
        'publishedAt': json['publishedAt'] == null ? undefined : (new Date(json['publishedAt'])),
        'closedAt': json['closedAt'] == null ? undefined : (new Date(json['closedAt'])),
        'status': JobStatusFromJSON(json['status']),
        'closureReason': json['closureReason'] == null ? undefined : JobClosureReasonFromJSON(json['closureReason']),
        'locationName': json['locationName'],
        'jobCategoryId': json['jobCategoryId'],
        'jobFunctionId': json['jobFunctionId'],
        'waitingJobApplicationCount': json['waitingJobApplicationCount'],
    };
}
export function ListJobsJobDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'description': JobDescriptionDtoToJSON(value['description']),
        'createdAt': ((value['createdAt']).toISOString()),
        'publishedAt': value['publishedAt'] == null ? undefined : (value['publishedAt'].toISOString()),
        'closedAt': value['closedAt'] == null ? undefined : (value['closedAt'].toISOString()),
        'status': JobStatusToJSON(value['status']),
        'closureReason': JobClosureReasonToJSON(value['closureReason']),
        'locationName': value['locationName'],
        'jobCategoryId': value['jobCategoryId'],
        'jobFunctionId': value['jobFunctionId'],
        'waitingJobApplicationCount': value['waitingJobApplicationCount'],
    };
}
