/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportingPeriodicityFromJSON, ReportingPeriodicityToJSON, } from './ReportingPeriodicity.js';
/**
 * Check if a given object implements the UpdateEmployerProfileCommandDto interface.
 */
export function instanceOfUpdateEmployerProfileCommandDto(value) {
    if (!('firstName' in value) || value['firstName'] === undefined)
        return false;
    if (!('lastName' in value) || value['lastName'] === undefined)
        return false;
    if (!('reportingPeriodicity' in value) || value['reportingPeriodicity'] === undefined)
        return false;
    return true;
}
export function UpdateEmployerProfileCommandDtoFromJSON(json) {
    return UpdateEmployerProfileCommandDtoFromJSONTyped(json, false);
}
export function UpdateEmployerProfileCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'reportingPeriodicity': ReportingPeriodicityFromJSON(json['reportingPeriodicity']),
    };
}
export function UpdateEmployerProfileCommandDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'reportingPeriodicity': ReportingPeriodicityToJSON(value['reportingPeriodicity']),
    };
}
