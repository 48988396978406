/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SendPollResult interface.
 */
export function instanceOfSendPollResult(value) {
    if (!('chatMessageId' in value) || value['chatMessageId'] === undefined)
        return false;
    if (!('pollId' in value) || value['pollId'] === undefined)
        return false;
    if (!('matchId' in value) || value['matchId'] === undefined)
        return false;
    return true;
}
export function SendPollResultFromJSON(json) {
    return SendPollResultFromJSONTyped(json, false);
}
export function SendPollResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'chatMessageId': json['chatMessageId'],
        'pollId': json['pollId'],
        'matchId': json['matchId'],
    };
}
export function SendPollResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'chatMessageId': value['chatMessageId'],
        'pollId': value['pollId'],
        'matchId': value['matchId'],
    };
}
