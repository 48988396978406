/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the RejectJobApplicationResult interface.
 */
export function instanceOfRejectJobApplicationResult(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    return true;
}
export function RejectJobApplicationResultFromJSON(json) {
    return RejectJobApplicationResultFromJSONTyped(json, false);
}
export function RejectJobApplicationResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
    };
}
export function RejectJobApplicationResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
    };
}
