/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatMessageDtoFromJSON, ChatMessageDtoToJSON, } from './ChatMessageDto.js';
import { CompanyDtoFromJSON, CompanyDtoToJSON, } from './CompanyDto.js';
/**
 * Check if a given object implements the SendMessageResponse interface.
 */
export function instanceOfSendMessageResponse(value) {
    if (!('company' in value) || value['company'] === undefined)
        return false;
    if (!('userId' in value) || value['userId'] === undefined)
        return false;
    if (!('chatMessage' in value) || value['chatMessage'] === undefined)
        return false;
    return true;
}
export function SendMessageResponseFromJSON(json) {
    return SendMessageResponseFromJSONTyped(json, false);
}
export function SendMessageResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'company': CompanyDtoFromJSON(json['company']),
        'userId': json['userId'],
        'chatMessage': ChatMessageDtoFromJSON(json['chatMessage']),
    };
}
export function SendMessageResponseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'company': CompanyDtoToJSON(value['company']),
        'userId': value['userId'],
        'chatMessage': ChatMessageDtoToJSON(value['chatMessage']),
    };
}
