/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListMatchesForJobUserDtoFromJSON, ListMatchesForJobUserDtoToJSON, } from './ListMatchesForJobUserDto.js';
/**
 * Check if a given object implements the ListMatchesForJobMatchDto interface.
 */
export function instanceOfListMatchesForJobMatchDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('lastMessageAt' in value) || value['lastMessageAt'] === undefined)
        return false;
    if (!('unreadMessageCount' in value) || value['unreadMessageCount'] === undefined)
        return false;
    if (!('user' in value) || value['user'] === undefined)
        return false;
    return true;
}
export function ListMatchesForJobMatchDtoFromJSON(json) {
    return ListMatchesForJobMatchDtoFromJSONTyped(json, false);
}
export function ListMatchesForJobMatchDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'closedAt': json['closedAt'] == null ? undefined : (new Date(json['closedAt'])),
        'lastMessageAt': (new Date(json['lastMessageAt'])),
        'unreadMessageCount': json['unreadMessageCount'],
        'user': ListMatchesForJobUserDtoFromJSON(json['user']),
    };
}
export function ListMatchesForJobMatchDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'createdAt': ((value['createdAt']).toISOString()),
        'closedAt': value['closedAt'] == null ? undefined : (value['closedAt'].toISOString()),
        'lastMessageAt': ((value['lastMessageAt']).toISOString()),
        'unreadMessageCount': value['unreadMessageCount'],
        'user': ListMatchesForJobUserDtoToJSON(value['user']),
    };
}
