/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListJobsJobDtoFromJSON, ListJobsJobDtoToJSON, } from './ListJobsJobDto.js';
/**
 * Check if a given object implements the ListJobsResult interface.
 */
export function instanceOfListJobsResult(value) {
    if (!('count' in value) || value['count'] === undefined)
        return false;
    if (!('totalCount' in value) || value['totalCount'] === undefined)
        return false;
    if (!('jobs' in value) || value['jobs'] === undefined)
        return false;
    return true;
}
export function ListJobsResultFromJSON(json) {
    return ListJobsResultFromJSONTyped(json, false);
}
export function ListJobsResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'count': json['count'],
        'totalCount': json['totalCount'],
        'jobs': (json['jobs'].map(ListJobsJobDtoFromJSON)),
    };
}
export function ListJobsResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'count': value['count'],
        'totalCount': value['totalCount'],
        'jobs': (value['jobs'].map(ListJobsJobDtoToJSON)),
    };
}
