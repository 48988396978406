/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TotalAmountFromJSON, TotalAmountToJSON, } from './TotalAmount.js';
/**
 * Check if a given object implements the TotalSpec interface.
 */
export function instanceOfTotalSpec(value) {
    if (!('taxExclusive' in value) || value['taxExclusive'] === undefined)
        return false;
    if (!('taxInclusive' in value) || value['taxInclusive'] === undefined)
        return false;
    return true;
}
export function TotalSpecFromJSON(json) {
    return TotalSpecFromJSONTyped(json, false);
}
export function TotalSpecFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'taxExclusive': TotalAmountFromJSON(json['tax_exclusive']),
        'taxInclusive': TotalAmountFromJSON(json['tax_inclusive']),
        'due': json['due'] == null ? undefined : TotalAmountFromJSON(json['due']),
    };
}
export function TotalSpecToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'tax_exclusive': TotalAmountToJSON(value['taxExclusive']),
        'tax_inclusive': TotalAmountToJSON(value['taxInclusive']),
        'due': TotalAmountToJSON(value['due']),
    };
}
