/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ListPaymentOptionsCreditPackage interface.
 */
export function instanceOfListPaymentOptionsCreditPackage(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('creditAmount' in value) || value['creditAmount'] === undefined)
        return false;
    if (!('creditPrice' in value) || value['creditPrice'] === undefined)
        return false;
    if (!('totalPrice' in value) || value['totalPrice'] === undefined)
        return false;
    return true;
}
export function ListPaymentOptionsCreditPackageFromJSON(json) {
    return ListPaymentOptionsCreditPackageFromJSONTyped(json, false);
}
export function ListPaymentOptionsCreditPackageFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'creditAmount': json['creditAmount'],
        'creditPrice': json['creditPrice'],
        'totalPrice': json['totalPrice'],
    };
}
export function ListPaymentOptionsCreditPackageToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'title': value['title'],
        'creditAmount': value['creditAmount'],
        'creditPrice': value['creditPrice'],
        'totalPrice': value['totalPrice'],
    };
}
