import type {
  GetCompanyStatisticsResult,
  ListUnhandledJobsJobDto,
} from '@swipe4work/api-client-fetch'
import { EmployerApi } from '@swipe4work/api-client-fetch'
import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import { useUserStore } from './user-store'
import { fetchConfiguration } from '@/modules/http'

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    api: new EmployerApi(fetchConfiguration),
    userStore: useUserStore(),
    statistics: undefined as GetCompanyStatisticsResult | undefined,
    unhandledJobs: [] as ListUnhandledJobsJobDto[],
    toast: useToast(),
    t: useI18n(),
  }),
  actions: {
    async fetchUnhandledJobs(): Promise<ListUnhandledJobsJobDto[]> {
      if (!this.userStore.hasReadPermission('Job')) {
        return []
      }
      const response = await this.api.listUnhandledJobs(10, 0)
      this.unhandledJobs = response.jobs
      return this.unhandledJobs
    },
    async fetchStatistics(): Promise<GetCompanyStatisticsResult> {
      const response = await this.api.getStatistics()
      this.statistics = response
      return this.statistics
    },
  },
})
