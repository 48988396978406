/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PollDtoFromJSON, PollDtoToJSON, } from './PollDto.js';
import { ChatMessageSenderRoleFromJSON, ChatMessageSenderRoleToJSON, } from './ChatMessageSenderRole.js';
/**
 * Check if a given object implements the ChatMessageDto interface.
 */
export function instanceOfChatMessageDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('matchId' in value) || value['matchId'] === undefined)
        return false;
    if (!('isRead' in value) || value['isRead'] === undefined)
        return false;
    if (!('senderRole' in value) || value['senderRole'] === undefined)
        return false;
    if (!('sender' in value) || value['sender'] === undefined)
        return false;
    if (!('body' in value) || value['body'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    return true;
}
export function ChatMessageDtoFromJSON(json) {
    return ChatMessageDtoFromJSONTyped(json, false);
}
export function ChatMessageDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'matchId': json['matchId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'employerId': json['employerId'] == null ? undefined : json['employerId'],
        'isRead': json['isRead'],
        'senderRole': ChatMessageSenderRoleFromJSON(json['senderRole']),
        'sender': json['sender'],
        'body': json['body'],
        'createdAt': (new Date(json['createdAt'])),
        'poll': json['poll'] == null ? undefined : PollDtoFromJSON(json['poll']),
    };
}
export function ChatMessageDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'matchId': value['matchId'],
        'userId': value['userId'],
        'employerId': value['employerId'],
        'isRead': value['isRead'],
        'senderRole': ChatMessageSenderRoleToJSON(value['senderRole']),
        'sender': value['sender'],
        'body': value['body'],
        'createdAt': ((value['createdAt']).toISOString()),
        'poll': PollDtoToJSON(value['poll']),
    };
}
