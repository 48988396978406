/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const JobClosureReason = {
    Filled: 'Filled',
    FilledOther: 'FilledOther',
    Expired: 'Expired'
};
export function instanceOfJobClosureReason(value) {
    for (const key in JobClosureReason) {
        if (Object.prototype.hasOwnProperty.call(JobClosureReason, key)) {
            if (JobClosureReason[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function JobClosureReasonFromJSON(json) {
    return JobClosureReasonFromJSONTyped(json, false);
}
export function JobClosureReasonFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function JobClosureReasonToJSON(value) {
    return value;
}
