/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SubscribeToJobCommandDto interface.
 */
export function instanceOfSubscribeToJobCommandDto(value) {
    if (!('jobId' in value) || value['jobId'] === undefined)
        return false;
    if (!('toSubscribe' in value) || value['toSubscribe'] === undefined)
        return false;
    return true;
}
export function SubscribeToJobCommandDtoFromJSON(json) {
    return SubscribeToJobCommandDtoFromJSONTyped(json, false);
}
export function SubscribeToJobCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'jobId': json['jobId'],
        'toSubscribe': json['toSubscribe'],
    };
}
export function SubscribeToJobCommandDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'jobId': value['jobId'],
        'toSubscribe': value['toSubscribe'],
    };
}
