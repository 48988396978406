/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NullableRangeDtoFromJSON, NullableRangeDtoToJSON, } from './NullableRangeDto.js';
/**
 * Check if a given object implements the ListMatchesForJobUserDto interface.
 */
export function instanceOfListMatchesForJobUserDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('displayName' in value) || value['displayName'] === undefined)
        return false;
    if (!('profilePictureUrl' in value) || value['profilePictureUrl'] === undefined)
        return false;
    if (!('cityName' in value) || value['cityName'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('dateOfBirth' in value) || value['dateOfBirth'] === undefined)
        return false;
    if (!('yearsExperience' in value) || value['yearsExperience'] === undefined)
        return false;
    if (!('educationLevelId' in value) || value['educationLevelId'] === undefined)
        return false;
    if (!('ambitions' in value) || value['ambitions'] === undefined)
        return false;
    if (!('personalInterests' in value) || value['personalInterests'] === undefined)
        return false;
    if (!('personalCharacteristics' in value) || value['personalCharacteristics'] === undefined)
        return false;
    if (!('personalityTypeId' in value) || value['personalityTypeId'] === undefined)
        return false;
    return true;
}
export function ListMatchesForJobUserDtoFromJSON(json) {
    return ListMatchesForJobUserDtoFromJSONTyped(json, false);
}
export function ListMatchesForJobUserDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'displayName': json['displayName'],
        'profilePictureUrl': json['profilePictureUrl'],
        'cityName': json['cityName'],
        'email': json['email'],
        'dateOfBirth': (new Date(json['dateOfBirth'])),
        'yearsExperience': NullableRangeDtoFromJSON(json['yearsExperience']),
        'educationLevelId': json['educationLevelId'],
        'ambitions': json['ambitions'],
        'personalInterests': json['personalInterests'],
        'newJobReason': json['newJobReason'] == null ? undefined : json['newJobReason'],
        'lastFunctionTitle': json['lastFunctionTitle'] == null ? undefined : json['lastFunctionTitle'],
        'personalCharacteristics': json['personalCharacteristics'],
        'personalityTypeId': json['personalityTypeId'],
    };
}
export function ListMatchesForJobUserDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'displayName': value['displayName'],
        'profilePictureUrl': value['profilePictureUrl'],
        'cityName': value['cityName'],
        'email': value['email'],
        'dateOfBirth': ((value['dateOfBirth']).toISOString().substring(0, 10)),
        'yearsExperience': NullableRangeDtoToJSON(value['yearsExperience']),
        'educationLevelId': value['educationLevelId'],
        'ambitions': value['ambitions'],
        'personalInterests': value['personalInterests'],
        'newJobReason': value['newJobReason'],
        'lastFunctionTitle': value['lastFunctionTitle'],
        'personalCharacteristics': value['personalCharacteristics'],
        'personalityTypeId': value['personalityTypeId'],
    };
}
