/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListPaymentOptionsSubscriptionDtoFromJSON, ListPaymentOptionsSubscriptionDtoToJSON, } from './ListPaymentOptionsSubscriptionDto.js';
import { ListPaymentOptionsCreditPackageFromJSON, ListPaymentOptionsCreditPackageToJSON, } from './ListPaymentOptionsCreditPackage.js';
/**
 * Check if a given object implements the ListPaymentOptionsResult interface.
 */
export function instanceOfListPaymentOptionsResult(value) {
    if (!('discount' in value) || value['discount'] === undefined)
        return false;
    if (!('creditPackages' in value) || value['creditPackages'] === undefined)
        return false;
    if (!('jobSlotOptions' in value) || value['jobSlotOptions'] === undefined)
        return false;
    if (!('subscriptions' in value) || value['subscriptions'] === undefined)
        return false;
    return true;
}
export function ListPaymentOptionsResultFromJSON(json) {
    return ListPaymentOptionsResultFromJSONTyped(json, false);
}
export function ListPaymentOptionsResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'discount': json['discount'],
        'creditPackages': (json['creditPackages'].map(ListPaymentOptionsCreditPackageFromJSON)),
        'jobSlotOptions': json['jobSlotOptions'],
        'subscriptions': (json['subscriptions'].map(ListPaymentOptionsSubscriptionDtoFromJSON)),
    };
}
export function ListPaymentOptionsResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'discount': value['discount'],
        'creditPackages': (value['creditPackages'].map(ListPaymentOptionsCreditPackageToJSON)),
        'jobSlotOptions': value['jobSlotOptions'],
        'subscriptions': (value['subscriptions'].map(ListPaymentOptionsSubscriptionDtoToJSON)),
    };
}
