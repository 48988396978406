/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const Periodicity = {
    Hourly: 'Hourly',
    Monthly: 'Monthly'
};
export function instanceOfPeriodicity(value) {
    for (const key in Periodicity) {
        if (Object.prototype.hasOwnProperty.call(Periodicity, key)) {
            if (Periodicity[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function PeriodicityFromJSON(json) {
    return PeriodicityFromJSONTyped(json, false);
}
export function PeriodicityFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PeriodicityToJSON(value) {
    return value;
}
