/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetCompanyAccountManagerDto interface.
 */
export function instanceOfGetCompanyAccountManagerDto(value) {
    if (!('firstName' in value) || value['firstName'] === undefined)
        return false;
    if (!('lastName' in value) || value['lastName'] === undefined)
        return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined)
        return false;
    return true;
}
export function GetCompanyAccountManagerDtoFromJSON(json) {
    return GetCompanyAccountManagerDtoFromJSONTyped(json, false);
}
export function GetCompanyAccountManagerDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'phoneNumber': json['phoneNumber'],
    };
}
export function GetCompanyAccountManagerDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'phoneNumber': value['phoneNumber'],
    };
}
