/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListAllMatchesMatchDtoFromJSON, ListAllMatchesMatchDtoToJSON, } from './ListAllMatchesMatchDto.js';
/**
 * Check if a given object implements the ListAllMatchesResult interface.
 */
export function instanceOfListAllMatchesResult(value) {
    if (!('count' in value) || value['count'] === undefined)
        return false;
    if (!('totalCount' in value) || value['totalCount'] === undefined)
        return false;
    if (!('matches' in value) || value['matches'] === undefined)
        return false;
    return true;
}
export function ListAllMatchesResultFromJSON(json) {
    return ListAllMatchesResultFromJSONTyped(json, false);
}
export function ListAllMatchesResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'count': json['count'],
        'totalCount': json['totalCount'],
        'matches': (json['matches'].map(ListAllMatchesMatchDtoFromJSON)),
    };
}
export function ListAllMatchesResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'count': value['count'],
        'totalCount': value['totalCount'],
        'matches': (value['matches'].map(ListAllMatchesMatchDtoToJSON)),
    };
}
