/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobApplicationStateFromJSON, JobApplicationStateToJSON, } from './JobApplicationState.js';
import { GetJobApplicationAnonymousUserFromJSON, GetJobApplicationAnonymousUserToJSON, } from './GetJobApplicationAnonymousUser.js';
/**
 * Check if a given object implements the GetJobApplicationResult interface.
 */
export function instanceOfGetJobApplicationResult(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('jobId' in value) || value['jobId'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('user' in value) || value['user'] === undefined)
        return false;
    if (!('matchScoreTotal' in value) || value['matchScoreTotal'] === undefined)
        return false;
    if (!('isCompleteProfileRequestSent' in value) || value['isCompleteProfileRequestSent'] === undefined)
        return false;
    return true;
}
export function GetJobApplicationResultFromJSON(json) {
    return GetJobApplicationResultFromJSONTyped(json, false);
}
export function GetJobApplicationResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'jobId': json['jobId'],
        'createdAt': (new Date(json['createdAt'])),
        'respondedAt': json['respondedAt'] == null ? undefined : (new Date(json['respondedAt'])),
        'status': JobApplicationStateFromJSON(json['status']),
        'matchId': json['matchId'] == null ? undefined : json['matchId'],
        'user': GetJobApplicationAnonymousUserFromJSON(json['user']),
        'matchScoreTotal': json['matchScoreTotal'],
        'isCompleteProfileRequestSent': json['isCompleteProfileRequestSent'],
    };
}
export function GetJobApplicationResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'jobId': value['jobId'],
        'createdAt': ((value['createdAt']).toISOString()),
        'respondedAt': value['respondedAt'] == null ? undefined : (value['respondedAt'].toISOString()),
        'status': JobApplicationStateToJSON(value['status']),
        'matchId': value['matchId'],
        'user': GetJobApplicationAnonymousUserToJSON(value['user']),
        'matchScoreTotal': value['matchScoreTotal'],
        'isCompleteProfileRequestSent': value['isCompleteProfileRequestSent'],
    };
}
