/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const JobStatus = {
    Draft: 'Draft',
    Active: 'Active',
    Closed: 'Closed'
};
export function instanceOfJobStatus(value) {
    for (const key in JobStatus) {
        if (Object.prototype.hasOwnProperty.call(JobStatus, key)) {
            if (JobStatus[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function JobStatusFromJSON(json) {
    return JobStatusFromJSONTyped(json, false);
}
export function JobStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function JobStatusToJSON(value) {
    return value;
}
