<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useCurrentUser } from 'vuefire'
import * as Sentry from '@sentry/vue'
import { useGeneralStore } from './store/general'
import { useAuthStore } from './store/auth'

const authStore = useAuthStore()
const generalStore = useGeneralStore()
const { authError } = storeToRefs(authStore)
const { loadFailed } = storeToRefs(generalStore)
const user = useCurrentUser()

// we don't need this watcher on server
onMounted(() => {
  watch(user, (user, prevUser) => {
    if (user && !prevUser) {
      Sentry.setUser({ id: user.uid ?? undefined })
    }

    if (!user) {
      Sentry.setUser(null)
    }
  })
})

useHead({
  title: 'Swipe4Work',
  meta: [
    {
      name: 'description',
      content: 'Swipe4Work werkgeversportaal',
    },
  ],
})
</script>

<template>
  <div v-if="authError || loadFailed">
    <!-- Show an error when authentication failed -->
    <div class="layout-main-container">
      <!-- Center the error/loading spinner -->
      <div class="flex grow items-center justify-center">
        <template v-if="authError">
          {{ $t("failedToLogIn") }}
        </template>
        <template v-else-if="loadFailed">
          {{ $t("failedToLoad") }}
        </template>
      </div>
    </div>
  </div>
  <template v-else>
    <suspense>
      <router-view />
    </suspense>
  </template>
  <ConfirmDialog />
</template>

<style lang="scss">
@import 'App.scss';
</style>
