/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ListAllMatchesMatchDto interface.
 */
export function instanceOfListAllMatchesMatchDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('profilePictureUrl' in value) || value['profilePictureUrl'] === undefined)
        return false;
    if (!('lastMessage' in value) || value['lastMessage'] === undefined)
        return false;
    if (!('lastMessageAt' in value) || value['lastMessageAt'] === undefined)
        return false;
    if (!('userName' in value) || value['userName'] === undefined)
        return false;
    if (!('jobTitle' in value) || value['jobTitle'] === undefined)
        return false;
    if (!('unreadMessageCount' in value) || value['unreadMessageCount'] === undefined)
        return false;
    return true;
}
export function ListAllMatchesMatchDtoFromJSON(json) {
    return ListAllMatchesMatchDtoFromJSONTyped(json, false);
}
export function ListAllMatchesMatchDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'profilePictureUrl': json['profilePictureUrl'],
        'lastMessage': json['lastMessage'],
        'lastMessageAt': (new Date(json['lastMessageAt'])),
        'closedAt': json['closedAt'] == null ? undefined : (new Date(json['closedAt'])),
        'userName': json['userName'],
        'jobTitle': json['jobTitle'],
        'unreadMessageCount': json['unreadMessageCount'],
    };
}
export function ListAllMatchesMatchDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'createdAt': ((value['createdAt']).toISOString()),
        'profilePictureUrl': value['profilePictureUrl'],
        'lastMessage': value['lastMessage'],
        'lastMessageAt': ((value['lastMessageAt']).toISOString()),
        'closedAt': value['closedAt'] == null ? undefined : (value['closedAt'].toISOString()),
        'userName': value['userName'],
        'jobTitle': value['jobTitle'],
        'unreadMessageCount': value['unreadMessageCount'],
    };
}
