/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobApplicationStateFromJSON, JobApplicationStateToJSON, } from './JobApplicationState.js';
import { ListJobApplicationsForJobUserDtoFromJSON, ListJobApplicationsForJobUserDtoToJSON, } from './ListJobApplicationsForJobUserDto.js';
/**
 * Check if a given object implements the ListJobApplicationsForJobJobApplication interface.
 */
export function instanceOfListJobApplicationsForJobJobApplication(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('user' in value) || value['user'] === undefined)
        return false;
    if (!('matchScoreTotal' in value) || value['matchScoreTotal'] === undefined)
        return false;
    return true;
}
export function ListJobApplicationsForJobJobApplicationFromJSON(json) {
    return ListJobApplicationsForJobJobApplicationFromJSONTyped(json, false);
}
export function ListJobApplicationsForJobJobApplicationFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'status': JobApplicationStateFromJSON(json['status']),
        'user': ListJobApplicationsForJobUserDtoFromJSON(json['user']),
        'matchScoreTotal': json['matchScoreTotal'],
    };
}
export function ListJobApplicationsForJobJobApplicationToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'createdAt': ((value['createdAt']).toISOString()),
        'status': JobApplicationStateToJSON(value['status']),
        'user': ListJobApplicationsForJobUserDtoToJSON(value['user']),
        'matchScoreTotal': value['matchScoreTotal'],
    };
}
