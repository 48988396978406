/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SkillDtoFromJSON, SkillDtoToJSON, } from './SkillDto.js';
import { EducationExperienceDtoFromJSON, EducationExperienceDtoToJSON, } from './EducationExperienceDto.js';
import { WorkExperienceDtoFromJSON, WorkExperienceDtoToJSON, } from './WorkExperienceDto.js';
import { NullableRangeDtoFromJSON, NullableRangeDtoToJSON, } from './NullableRangeDto.js';
import { RangeDtoFromJSON, RangeDtoToJSON, } from './RangeDto.js';
/**
 * Check if a given object implements the GetMatchedUserResult interface.
 */
export function instanceOfGetMatchedUserResult(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('profilePictureUrl' in value) || value['profilePictureUrl'] === undefined)
        return false;
    if (!('firstName' in value) || value['firstName'] === undefined)
        return false;
    if (!('lastName' in value) || value['lastName'] === undefined)
        return false;
    if (!('displayName' in value) || value['displayName'] === undefined)
        return false;
    if (!('cityName' in value) || value['cityName'] === undefined)
        return false;
    if (!('skills' in value) || value['skills'] === undefined)
        return false;
    if (!('topSkills' in value) || value['topSkills'] === undefined)
        return false;
    if (!('yearsExperience' in value) || value['yearsExperience'] === undefined)
        return false;
    if (!('educationLevel' in value) || value['educationLevel'] === undefined)
        return false;
    if (!('personalCharacteristics' in value) || value['personalCharacteristics'] === undefined)
        return false;
    if (!('personalityTypeId' in value) || value['personalityTypeId'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined)
        return false;
    if (!('allowPhoneCalls' in value) || value['allowPhoneCalls'] === undefined)
        return false;
    if (!('allowTexts' in value) || value['allowTexts'] === undefined)
        return false;
    if (!('prefersContactOverPhone' in value) || value['prefersContactOverPhone'] === undefined)
        return false;
    if (!('hoursPerWeek' in value) || value['hoursPerWeek'] === undefined)
        return false;
    if (!('hasCompleteProfile' in value) || value['hasCompleteProfile'] === undefined)
        return false;
    if (!('educationExperiences' in value) || value['educationExperiences'] === undefined)
        return false;
    if (!('noEducationExperience' in value) || value['noEducationExperience'] === undefined)
        return false;
    if (!('workExperiences' in value) || value['workExperiences'] === undefined)
        return false;
    if (!('noWorkExperience' in value) || value['noWorkExperience'] === undefined)
        return false;
    if (!('workExperienceComplete' in value) || value['workExperienceComplete'] === undefined)
        return false;
    if (!('ambitions' in value) || value['ambitions'] === undefined)
        return false;
    if (!('personalInterests' in value) || value['personalInterests'] === undefined)
        return false;
    return true;
}
export function GetMatchedUserResultFromJSON(json) {
    return GetMatchedUserResultFromJSONTyped(json, false);
}
export function GetMatchedUserResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'profilePictureUrl': json['profilePictureUrl'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'displayName': json['displayName'],
        'cityName': json['cityName'],
        'skills': (json['skills'].map(SkillDtoFromJSON)),
        'topSkills': (json['topSkills'].map(SkillDtoFromJSON)),
        'yearsExperience': NullableRangeDtoFromJSON(json['yearsExperience']),
        'educationLevel': json['educationLevel'],
        'personalCharacteristics': json['personalCharacteristics'],
        'personalityTypeId': json['personalityTypeId'],
        'age': json['age'] == null ? undefined : json['age'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'allowPhoneCalls': json['allowPhoneCalls'],
        'allowTexts': json['allowTexts'],
        'prefersContactOverPhone': json['prefersContactOverPhone'],
        'hoursPerWeek': RangeDtoFromJSON(json['hoursPerWeek']),
        'hasCompleteProfile': json['hasCompleteProfile'],
        'educationExperiences': (json['educationExperiences'].map(EducationExperienceDtoFromJSON)),
        'noEducationExperience': json['noEducationExperience'],
        'workExperiences': (json['workExperiences'].map(WorkExperienceDtoFromJSON)),
        'noWorkExperience': json['noWorkExperience'],
        'workExperienceComplete': json['workExperienceComplete'],
        'ambitions': json['ambitions'],
        'personalInterests': json['personalInterests'],
        'newJobReason': json['newJobReason'] == null ? undefined : json['newJobReason'],
    };
}
export function GetMatchedUserResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'profilePictureUrl': value['profilePictureUrl'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'displayName': value['displayName'],
        'cityName': value['cityName'],
        'skills': (value['skills'].map(SkillDtoToJSON)),
        'topSkills': (value['topSkills'].map(SkillDtoToJSON)),
        'yearsExperience': NullableRangeDtoToJSON(value['yearsExperience']),
        'educationLevel': value['educationLevel'],
        'personalCharacteristics': value['personalCharacteristics'],
        'personalityTypeId': value['personalityTypeId'],
        'age': value['age'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'allowPhoneCalls': value['allowPhoneCalls'],
        'allowTexts': value['allowTexts'],
        'prefersContactOverPhone': value['prefersContactOverPhone'],
        'hoursPerWeek': RangeDtoToJSON(value['hoursPerWeek']),
        'educationExperiences': (value['educationExperiences'].map(EducationExperienceDtoToJSON)),
        'noEducationExperience': value['noEducationExperience'],
        'workExperiences': (value['workExperiences'].map(WorkExperienceDtoToJSON)),
        'noWorkExperience': value['noWorkExperience'],
        'workExperienceComplete': value['workExperienceComplete'],
        'ambitions': value['ambitions'],
        'personalInterests': value['personalInterests'],
        'newJobReason': value['newJobReason'],
    };
}
