import type {
  GetEmployerProfileResult,
  PermissionDto,
} from '@swipe4work/api-client-fetch'
import { EmployerApi, GeneralApi, PermissionLevel } from '@swipe4work/api-client-fetch'

import { defineStore } from 'pinia'
import posthog from 'posthog-js'
import { fetchConfiguration } from '@/modules/http'

export type PermissionResource = 'Dashboard' | 'CompanyProfile' | 'CompanySettings' | 'Job' | 'JobApplication' | 'Scout' | 'Match'

export const useUserStore = defineStore('user', {
  state: () => ({
    employerApi: new EmployerApi(fetchConfiguration),
    generalApi: new GeneralApi(fetchConfiguration),
    profile: undefined as GetEmployerProfileResult | undefined,
  }),
  getters: {
    isOriginalCompany: (state) => {
      return state.profile?.originalCompanyId === undefined || state.profile?.companyId === state.profile?.originalCompanyId
    },
    canAccessOtherCompanies: (state) => {
      return state.profile?.canAccessOtherCompanies
    },
    permissions: (state) => {
      return state.profile?.permissions
    },
    hasSubscription: state => state.profile?.hasSubscription,
    isLead: state => state.profile?.roles.includes('Lead'),
  },
  actions: {
    async fetchProfile() {
      try {
        const profileResponse = await this.employerApi.getProfile()
        this.profile = profileResponse
        posthog.identify(this.profile.externalId, {
          'is-lead': this.isLead,
        })
      } catch (error) {
        console.error(error)
      }
      return this.profile
    },
    async signOut() {
      this.profile = undefined
      posthog.reset()
    },
    hasFullPermissions(): boolean {
      return this.profile?.permissions.map(p => p.resource).includes('Full') ?? false
    },
    hasPermission(requiredPermission?: PermissionDto): boolean {
      if (requiredPermission === undefined) {
        return true
      }
      if (requiredPermission.level === PermissionLevel.Read) {
        return this.hasReadPermission(requiredPermission.resource as PermissionResource)
      }
      return this.hasWritePermission(requiredPermission.resource as PermissionResource)
    },
    // Has permission to read or write
    hasReadPermission(resource: PermissionResource): boolean {
      if (this.profile === undefined) {
        return false
      }

      if (this.hasFullPermissions()) {
        return true
      }

      return this.profile.permissions.map(p => p.resource).includes(resource) ?? false
    },
    // Has permission to write
    hasWritePermission(resource: PermissionResource): boolean {
      if (this.profile === undefined) {
        return false
      }

      if (this.hasFullPermissions()) {
        return true
      }

      return this.profile.permissions.find(p => p.resource === resource)?.level === PermissionLevel.Write
    },
    canCreateJob(): boolean {
      return this.hasWritePermission('Job')
    },
  },
})
