/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const EmployerMatchClosureReason = {
    RejectedBeforeInterview: 'RejectedBeforeInterview',
    RejectedAfterInterview: 'RejectedAfterInterview',
    CandidateWithdrew: 'CandidateWithdrew',
    JobFilledWithOtherCandidate: 'JobFilledWithOtherCandidate',
    JobFilledThroughOtherChannel: 'JobFilledThroughOtherChannel',
    JobExpired: 'JobExpired',
    CandidateHired: 'CandidateHired'
};
export function instanceOfEmployerMatchClosureReason(value) {
    for (const key in EmployerMatchClosureReason) {
        if (Object.prototype.hasOwnProperty.call(EmployerMatchClosureReason, key)) {
            if (EmployerMatchClosureReason[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function EmployerMatchClosureReasonFromJSON(json) {
    return EmployerMatchClosureReasonFromJSONTyped(json, false);
}
export function EmployerMatchClosureReasonFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function EmployerMatchClosureReasonToJSON(value) {
    return value;
}
