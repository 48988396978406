/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemFromJSON, ItemToJSON, } from './Item.js';
import { EmployerMatchClosureReasonFromJSON, EmployerMatchClosureReasonToJSON, } from './EmployerMatchClosureReason.js';
/**
 * Check if a given object implements the GetMatchResult interface.
 */
export function instanceOfGetMatchResult(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('userFirstName' in value) || value['userFirstName'] === undefined)
        return false;
    if (!('userDisplayName' in value) || value['userDisplayName'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('suggestedAndNegativeCredits' in value) || value['suggestedAndNegativeCredits'] === undefined)
        return false;
    if (!('jobId' in value) || value['jobId'] === undefined)
        return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined)
        return false;
    if (!('allowPhoneCalls' in value) || value['allowPhoneCalls'] === undefined)
        return false;
    if (!('allowTexts' in value) || value['allowTexts'] === undefined)
        return false;
    if (!('prefersContactOverPhone' in value) || value['prefersContactOverPhone'] === undefined)
        return false;
    return true;
}
export function GetMatchResultFromJSON(json) {
    return GetMatchResultFromJSONTyped(json, false);
}
export function GetMatchResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'userFirstName': json['userFirstName'],
        'userDisplayName': json['userDisplayName'],
        'createdAt': (new Date(json['createdAt'])),
        'closedAt': json['closedAt'] == null ? undefined : (new Date(json['closedAt'])),
        'suggestedAt': json['suggestedAt'] == null ? undefined : (new Date(json['suggestedAt'])),
        'suggestedAndNegativeCredits': json['suggestedAndNegativeCredits'],
        'employerClosureReason': json['employerClosureReason'] == null ? undefined : EmployerMatchClosureReasonFromJSON(json['employerClosureReason']),
        'userClosureReason': json['userClosureReason'] == null ? undefined : ItemFromJSON(json['userClosureReason']),
        'jobId': json['jobId'],
        'phoneNumber': json['phoneNumber'],
        'allowPhoneCalls': json['allowPhoneCalls'],
        'allowTexts': json['allowTexts'],
        'prefersContactOverPhone': json['prefersContactOverPhone'],
    };
}
export function GetMatchResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'userFirstName': value['userFirstName'],
        'userDisplayName': value['userDisplayName'],
        'createdAt': ((value['createdAt']).toISOString()),
        'closedAt': value['closedAt'] == null ? undefined : (value['closedAt'].toISOString()),
        'suggestedAt': value['suggestedAt'] == null ? undefined : (value['suggestedAt'].toISOString()),
        'suggestedAndNegativeCredits': value['suggestedAndNegativeCredits'],
        'employerClosureReason': EmployerMatchClosureReasonToJSON(value['employerClosureReason']),
        'userClosureReason': ItemToJSON(value['userClosureReason']),
        'jobId': value['jobId'],
        'phoneNumber': value['phoneNumber'],
        'allowPhoneCalls': value['allowPhoneCalls'],
        'allowTexts': value['allowTexts'],
        'prefersContactOverPhone': value['prefersContactOverPhone'],
    };
}
