/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the EducationExperienceDto interface.
 */
export function instanceOfEducationExperienceDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('studyAndGrade' in value) || value['studyAndGrade'] === undefined)
        return false;
    if (!('institute' in value) || value['institute'] === undefined)
        return false;
    if (!('startYear' in value) || value['startYear'] === undefined)
        return false;
    if (!('isGraduated' in value) || value['isGraduated'] === undefined)
        return false;
    return true;
}
export function EducationExperienceDtoFromJSON(json) {
    return EducationExperienceDtoFromJSONTyped(json, false);
}
export function EducationExperienceDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'studyAndGrade': json['studyAndGrade'],
        'institute': json['institute'],
        'startYear': json['startYear'],
        'endYear': json['endYear'] == null ? undefined : json['endYear'],
        'isGraduated': json['isGraduated'],
    };
}
export function EducationExperienceDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'studyAndGrade': value['studyAndGrade'],
        'institute': value['institute'],
        'startYear': value['startYear'],
        'endYear': value['endYear'],
        'isGraduated': value['isGraduated'],
    };
}
