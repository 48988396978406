/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NullableRangeDtoFromJSON, NullableRangeDtoToJSON, } from './NullableRangeDto.js';
/**
 * Check if a given object implements the ListAllWaitingJobApplicationsUserDto interface.
 */
export function instanceOfListAllWaitingJobApplicationsUserDto(value) {
    if (!('cityName' in value) || value['cityName'] === undefined)
        return false;
    if (!('educationLevel' in value) || value['educationLevel'] === undefined)
        return false;
    if (!('personalCharacteristics' in value) || value['personalCharacteristics'] === undefined)
        return false;
    if (!('personalityTypeId' in value) || value['personalityTypeId'] === undefined)
        return false;
    return true;
}
export function ListAllWaitingJobApplicationsUserDtoFromJSON(json) {
    return ListAllWaitingJobApplicationsUserDtoFromJSONTyped(json, false);
}
export function ListAllWaitingJobApplicationsUserDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'cityName': json['cityName'],
        'yearsExperience': json['yearsExperience'] == null ? undefined : NullableRangeDtoFromJSON(json['yearsExperience']),
        'educationLevel': json['educationLevel'],
        'personalCharacteristics': json['personalCharacteristics'],
        'personalityTypeId': json['personalityTypeId'],
        'lastFunctionTitle': json['lastFunctionTitle'] == null ? undefined : json['lastFunctionTitle'],
    };
}
export function ListAllWaitingJobApplicationsUserDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'cityName': value['cityName'],
        'yearsExperience': NullableRangeDtoToJSON(value['yearsExperience']),
        'educationLevel': value['educationLevel'],
        'personalCharacteristics': value['personalCharacteristics'],
        'personalityTypeId': value['personalityTypeId'],
        'lastFunctionTitle': value['lastFunctionTitle'],
    };
}
