/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NullableRangeDtoFromJSON, NullableRangeDtoToJSON, } from './NullableRangeDto.js';
import { PointDtoFromJSON, PointDtoToJSON, } from './PointDto.js';
/**
 * Check if a given object implements the CreateCompanyForEmployerCompanyDto interface.
 */
export function instanceOfCreateCompanyForEmployerCompanyDto(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('locationName' in value) || value['locationName'] === undefined)
        return false;
    if (!('geolocation' in value) || value['geolocation'] === undefined)
        return false;
    if (!('employeeCount' in value) || value['employeeCount'] === undefined)
        return false;
    return true;
}
export function CreateCompanyForEmployerCompanyDtoFromJSON(json) {
    return CreateCompanyForEmployerCompanyDtoFromJSONTyped(json, false);
}
export function CreateCompanyForEmployerCompanyDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'locationName': json['locationName'],
        'geolocation': PointDtoFromJSON(json['geolocation']),
        'employeeCount': NullableRangeDtoFromJSON(json['employeeCount']),
    };
}
export function CreateCompanyForEmployerCompanyDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'locationName': value['locationName'],
        'geolocation': PointDtoToJSON(value['geolocation']),
        'employeeCount': NullableRangeDtoToJSON(value['employeeCount']),
    };
}
