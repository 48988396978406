/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemFromJSON, ItemToJSON, } from './Item.js';
/**
 * Check if a given object implements the ListInterestsResult interface.
 */
export function instanceOfListInterestsResult(value) {
    if (!('interests' in value) || value['interests'] === undefined)
        return false;
    return true;
}
export function ListInterestsResultFromJSON(json) {
    return ListInterestsResultFromJSONTyped(json, false);
}
export function ListInterestsResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'interests': (json['interests'].map(ItemFromJSON)),
    };
}
export function ListInterestsResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'interests': (value['interests'].map(ItemToJSON)),
    };
}
