/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DefaultMessageDtoFromJSON, DefaultMessageDtoToJSON, } from './DefaultMessageDto.js';
/**
 * Check if a given object implements the AddOrUpdateDefaultMessageResult interface.
 */
export function instanceOfAddOrUpdateDefaultMessageResult(value) {
    if (!('defaultMessages' in value) || value['defaultMessages'] === undefined)
        return false;
    return true;
}
export function AddOrUpdateDefaultMessageResultFromJSON(json) {
    return AddOrUpdateDefaultMessageResultFromJSONTyped(json, false);
}
export function AddOrUpdateDefaultMessageResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'defaultMessages': (json['defaultMessages'].map(DefaultMessageDtoFromJSON)),
    };
}
export function AddOrUpdateDefaultMessageResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'defaultMessages': (value['defaultMessages'].map(DefaultMessageDtoToJSON)),
    };
}
