/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PointDtoFromJSON, PointDtoToJSON, } from './PointDto.js';
/**
 * Check if a given object implements the GetLocationGeocodeResult interface.
 */
export function instanceOfGetLocationGeocodeResult(value) {
    if (!('coordinates' in value) || value['coordinates'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('placeId' in value) || value['placeId'] === undefined)
        return false;
    return true;
}
export function GetLocationGeocodeResultFromJSON(json) {
    return GetLocationGeocodeResultFromJSONTyped(json, false);
}
export function GetLocationGeocodeResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'coordinates': PointDtoFromJSON(json['coordinates']),
        'name': json['name'],
        'placeId': json['placeId'],
    };
}
export function GetLocationGeocodeResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'coordinates': PointDtoToJSON(value['coordinates']),
        'name': value['name'],
        'placeId': value['placeId'],
    };
}
