export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/index.vue'),
    /* no children */
    meta: {
      "permission": "Dashboard"
    }
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": 404
    }
  },
  {
    path: '/auth',
    /* internal name: '/auth' */
    /* no component */
    children: [
      {
        path: 'redirectLogin/:token',
        name: '/auth/redirectLogin.[token]',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/auth/redirectLogin.[token].vue'),
        /* no children */
        meta: {
          "allowAnonymous": true,
          "layout": "empty"
        }
      }
    ],
  },
  {
    path: '/company',
    /* internal name: '/company' */
    /* no component */
    children: [
      {
        path: '',
        name: '/company/',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/company/index.vue'),
        /* no children */
        meta: {
          "permissionResource": "CompanyProfile"
        }
      }
    ],
  },
  {
    path: '/employee',
    name: '/employee',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/employee.vue'),
    /* no children */
    meta: {
      "layout": "empty"
    }
  },
  {
    path: '/Error',
    name: '/Error',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/Error.vue'),
    /* no children */
    meta: {
      "layout": "error"
    }
  },
  {
    path: '/instellingen',
    name: '/settings',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings.vue'),
    children: [
      {
        path: '/instellingen/account',
        name: '/settings/Account',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings/Account.vue'),
        /* no children */
      },
      {
        path: '/instellingen/algemene-voorwaarden',
        name: '/settings/TermsAndConditions',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings/TermsAndConditions.vue'),
        /* no children */
      },
      {
        path: '/instellingen/gebruikersprofiel',
        name: '/settings/UserProfile',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings/UserProfile.vue'),
        /* no children */
      },
      {
        path: '/instellingen/standaard-berichten',
        name: '/settings/DefaultMessages',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings/DefaultMessages.vue'),
        /* no children */
      },
      {
        path: '/instellingen/teamgenoten',
        name: '/settings/Teammates',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/settings/Teammates.vue'),
        /* no children */
      }
    ],
    meta: {
      "permissionResource": "CompanySettings"
    }
  },
  {
    path: '/invites',
    /* internal name: '/invites' */
    /* no component */
    children: [
      {
        path: '',
        name: '/invites/',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/invites/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/job-applications',
    /* internal name: '/job-applications' */
    /* no component */
    children: [
      {
        path: '',
        name: '/job-applications/',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/job-applications/index.vue'),
        /* no children */
        meta: {
          "permissionResource": "JobApplication"
        }
      },
      {
        path: ':jobApplicationId',
        name: '/job-applications/[jobApplicationId]',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/job-applications/[jobApplicationId].vue'),
        /* no children */
        meta: {
          "permissionResource": "JobApplication"
        }
      }
    ],
  },
  {
    path: '/jobs',
    /* internal name: '/jobs' */
    /* no component */
    children: [
      {
        path: '',
        name: '/jobs/',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/index.vue'),
        /* no children */
        meta: {
          "permissionResource": "Job"
        }
      },
      {
        path: ':jobId',
        name: '/jobs/[jobId]',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId].vue'),
        children: [
          {
            path: 'applicants',
            name: '/jobs/[jobId]/applicants',
            component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId]/applicants.vue'),
            /* no children */
          },
          {
            path: 'matches',
            name: '/jobs/[jobId]/matches',
            component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId]/matches.vue'),
            /* no children */
          },
          {
            path: 'rejected-applicants',
            name: '/jobs/[jobId]/rejected-applicants',
            component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId]/rejected-applicants.vue'),
            /* no children */
          }
        ],
      },
      {
        path: ':jobId/duplicate',
        name: '/jobs/[jobId].duplicate',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId].duplicate.vue'),
        /* no children */
        meta: {
          "permissionResource": "Job",
          "permissionLevel": "Write"
        }
      },
      {
        path: ':jobId/update',
        name: '/jobs/[jobId].update',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/[jobId].update.vue'),
        /* no children */
        meta: {
          "permissionResource": "Job",
          "permissionLevel": "Write"
        }
      },
      {
        path: 'create',
        name: '/jobs/create',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/jobs/create.vue'),
        /* no children */
        meta: {
          "permissionResource": "Job",
          "permissionLevel": "Write"
        }
      }
    ],
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "empty",
      "allowAnonymous": true
    }
  },
  {
    path: '/matches',
    /* internal name: '/matches' */
    /* no component */
    children: [
      {
        path: '',
        name: '/matches/',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/matches/index.vue'),
        /* no children */
        meta: {
          "permissionResource": "Match"
        }
      },
      {
        path: ':id',
        name: '/matches/[id]',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/matches/[id].vue'),
        /* no children */
        meta: {
          "permissionResource": "Match"
        }
      }
    ],
  },
  {
    path: '/onboarding',
    name: '/onboarding',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding.vue'),
    children: [
      {
        path: 'billing',
        name: '/onboarding/billing',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding/billing.vue'),
        /* no children */
      },
      {
        path: 'company',
        name: '/onboarding/company',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding/company.vue'),
        /* no children */
      },
      {
        path: 'register',
        name: '/onboarding/register',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding/register.vue'),
        /* no children */
        meta: {
          "allowAnonymous": true
        }
      },
      {
        path: 'subscription',
        name: '/onboarding/subscription',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/onboarding/subscription.vue'),
        /* no children */
      }
    ],
    meta: {
      "layout": "onboarding",
      "isOnboarding": true
    }
  },
  {
    path: '/reset-password',
    name: '/reset-password',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/reset-password.vue'),
    /* no children */
    meta: {
      "layout": "empty",
      "allowAnonymous": true
    }
  },
  {
    path: '/verbruik',
    name: '/usage',
    component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/usage.vue'),
    children: [
      {
        path: '/verbruik/abonnement',
        name: '/usage/Subscription',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/usage/Subscription.vue'),
        /* no children */
      },
      {
        path: '/verbruik/crediet',
        name: '/usage/Credits',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/usage/Credits.vue'),
        /* no children */
      },
      {
        path: '/verbruik/facturen',
        name: '/usage/Invoices',
        component: () => import('/home/runner/work/frontend-employer/frontend-employer/src/pages/usage/Invoices.vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

