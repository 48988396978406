import { defineStore } from 'pinia'
import {
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth'
import { GeneralApi, Role } from '@swipe4work/api-client-fetch'
import type { FirebaseError } from 'firebase/app'
import { getCurrentUser, useFirebaseAuth } from 'vuefire'
import { useToast } from 'vue-toastification'
import type { AccountInfo, ExternalTokenResponse, SilentRequest } from '@azure/msal-browser'
import { PublicClientApplication } from '@azure/msal-browser'
import { useLocalStorage } from '@vueuse/core'
import { fetchConfiguration } from '@/modules/http'

/**
 * It's possible to login with either Firebase or MSAL.
 * This store handles both cases.
 */
export async function getAccessToken() {
  const msalClient = getMsalClientApplication()
  await msalClient.initialize()

  const msalAccount = msalClient.getActiveAccount()
  if (msalAccount !== null) {
    const silentRequest: SilentRequest = {
      scopes: ['openid', import.meta.env.VITE_AZURE_AD_SCOPE_API || ''],
      account: msalAccount,
    }
    const response = await msalClient.acquireTokenSilent(silentRequest)
    return response.accessToken
  }

  const user = await getCurrentUser()

  return user?.getIdToken()
}

export function getMsalClientApplication() {
  return new PublicClientApplication({
    auth: {
      clientId: import.meta.env.VITE_AZURE_AD_CLIENT_ID || '',
      authority: import.meta.env.VITE_AZURE_AD_AUTHORITY,
      redirectUri: import.meta.env.VITE_AZURE_AD_REDIRECT_URI || '',
      postLogoutRedirectUri: import.meta.env.VITE_AZURE_AD_POST_LOGOUT_REDIRECT_URI || '',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  })
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    generalApi: new GeneralApi(fetchConfiguration),
    router: useRouter(),
    t: useI18n(),
    role: undefined as Role | undefined,
    authError: undefined as Error | undefined,
    firebaseAuth: useFirebaseAuth(),
    msal: {
      user: null as AccountInfo | null,
      accessToken: null as string | null,
      scopes: ['openid', import.meta.env.VITE_AZURE_AD_SCOPE_API || ''],
    },
  }),
  getters: {
    isEmployeeUser: state => (state.role && state.role === Role.User) || state.role === Role.OnboardingUser,
  },
  actions: {
    isMsalUser() {
      return useLocalStorage('isMsalUser', false)
    },
    async initForMsal() {
      if (this.msal.user || !this.isMsalUser().value) {
        return
      }

      const client = getMsalClientApplication()
      await client.initialize()

      await this.initMsalAccount(client)
    },
    async initMsalAccount(client: PublicClientApplication) {
      const accounts = client.getAllAccounts()
      if (accounts.length > 0) {
        this.msal.user = accounts[accounts.length - 1]
      }

      if (!this.msal.user) {
        return
      }

      client.setActiveAccount(this.msal.user)

      const auth = await client.acquireTokenSilent({
        scopes: this.msal.scopes,
        authority: import.meta.env.VITE_AZURE_AD_AUTHORITY,
        account: this.msal.user,
        redirectUri: `${window.location.origin}/auth/redirect`,
      })
      this.msal.accessToken = auth.accessToken
    },
    async loginWithMsal(idToken: string) {
      if (this.msal.user || !this.isMsalUser().value) {
        return
      }

      const silentRequest: SilentRequest = {
        scopes: this.msal.scopes,
        authority: import.meta.env.VITE_AZURE_AD_AUTHORITY,
      }

      const serverResponse: ExternalTokenResponse = {
        id_token: idToken,
        client_info: '',
      }

      const client = getMsalClientApplication()

      await client.initialize()

      client.getTokenCache().loadExternalTokens(
        silentRequest,
        serverResponse,
        {},
      )

      await this.initMsalAccount(client)
    },
    async isAuthenticated() {
      if (this.msal.user) {
        return true
      }

      const fireBaseUser = await getCurrentUser()
      return !!fireBaseUser
    },
    async getRole() {
      if (this.role) {
        return this.role
      }

      return await this.fetchRole()
    },
    async fetchRole() {
      try {
        const roleResponse = await this.generalApi.getRole()
        this.role = roleResponse.role
      } catch (e) {
        const toast = useToast()
        toast.error(this.t.t('general.initialLoadFailed'), { timeout: undefined })
      }
      return this.role
    },
    async login(email: string, password: string): Promise<boolean> {
      try {
        const response = await signInWithEmailAndPassword(this.firebaseAuth!, email, password)
        if (!response) {
          this.authError = new Error('login failed')
          return false
        }
        return true
      } catch (Error) {
        return false
      }
    },

    async loginWithToken(token: string): Promise<boolean> {
      try {
        const response = await signInWithCustomToken(this.firebaseAuth!, token)
        if (!response) {
          this.authError = new Error('login failed')
          return false
        }
        return true
      } catch (Error) {
        return false
      }
    },
    async sendResetPasswordMail(email: string): Promise<FirebaseError | null> {
      try {
        await sendPasswordResetEmail(this.firebaseAuth!, email)
      } catch (e: unknown) {
        return e as FirebaseError
      }
      return null
    },
    async updatePassword(email: string, currentPassword: string, newPassword: string): Promise<FirebaseError | null> {
      try {
        const user = await signInWithEmailAndPassword(this.firebaseAuth!, email, currentPassword)
        await updatePassword(user.user, newPassword)
      } catch (e: unknown) {
        return e as FirebaseError
      }
      return null
    },
    async signOut() {
      if (this.msal.user) {
        const client = getMsalClientApplication()
        await client.initialize()
        await client.logoutRedirect()
        this.msal.user = null
        this.msal.accessToken = null
        localStorage.removeItem('isMsalUser')
      } else {
        await this.firebaseAuth!.signOut()
      }

      this.role = undefined
      this.router.push({ name: '/login' })
    },
  },
})
