/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HardSkillDtoFromJSON, HardSkillDtoToJSON, } from './HardSkillDto.js';
import { SkillDtoFromJSON, SkillDtoToJSON, } from './SkillDto.js';
/**
 * Check if a given object implements the ListAllSkillsResult interface.
 */
export function instanceOfListAllSkillsResult(value) {
    if (!('softSkills' in value) || value['softSkills'] === undefined)
        return false;
    if (!('languages' in value) || value['languages'] === undefined)
        return false;
    if (!('hardSkills' in value) || value['hardSkills'] === undefined)
        return false;
    return true;
}
export function ListAllSkillsResultFromJSON(json) {
    return ListAllSkillsResultFromJSONTyped(json, false);
}
export function ListAllSkillsResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'softSkills': (json['softSkills'].map(SkillDtoFromJSON)),
        'languages': (json['languages'].map(SkillDtoFromJSON)),
        'hardSkills': (json['hardSkills'].map(HardSkillDtoFromJSON)),
    };
}
export function ListAllSkillsResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'softSkills': (value['softSkills'].map(SkillDtoToJSON)),
        'languages': (value['languages'].map(SkillDtoToJSON)),
        'hardSkills': (value['hardSkills'].map(HardSkillDtoToJSON)),
    };
}
