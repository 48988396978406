/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InvoiceStatusFromJSON, InvoiceStatusToJSON, } from './InvoiceStatus.js';
/**
 * Check if a given object implements the ListInvoicesForCompanyInvoiceDto interface.
 */
export function instanceOfListInvoicesForCompanyInvoiceDto(value) {
    if (!('invoiceNumber' in value) || value['invoiceNumber'] === undefined)
        return false;
    if (!('invoiceDate' in value) || value['invoiceDate'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('paid' in value) || value['paid'] === undefined)
        return false;
    if (!('dueOn' in value) || value['dueOn'] === undefined)
        return false;
    if (!('daysOverdue' in value) || value['daysOverdue'] === undefined)
        return false;
    if (!('totalAmount' in value) || value['totalAmount'] === undefined)
        return false;
    if (!('due' in value) || value['due'] === undefined)
        return false;
    if (!('fileId' in value) || value['fileId'] === undefined)
        return false;
    return true;
}
export function ListInvoicesForCompanyInvoiceDtoFromJSON(json) {
    return ListInvoicesForCompanyInvoiceDtoFromJSONTyped(json, false);
}
export function ListInvoicesForCompanyInvoiceDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'invoiceNumber': json['invoiceNumber'],
        'invoiceDate': (new Date(json['invoiceDate'])),
        'status': InvoiceStatusFromJSON(json['status']),
        'paid': json['paid'],
        'dueOn': (new Date(json['dueOn'])),
        'daysOverdue': json['daysOverdue'],
        'totalAmount': json['totalAmount'],
        'due': json['due'],
        'fileId': json['fileId'],
    };
}
export function ListInvoicesForCompanyInvoiceDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'invoiceNumber': value['invoiceNumber'],
        'invoiceDate': ((value['invoiceDate']).toISOString().substring(0, 10)),
        'status': InvoiceStatusToJSON(value['status']),
        'paid': value['paid'],
        'dueOn': ((value['dueOn']).toISOString().substring(0, 10)),
        'totalAmount': value['totalAmount'],
        'due': value['due'],
        'fileId': value['fileId'],
    };
}
