/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetInvoiceFileUrlResult interface.
 */
export function instanceOfGetInvoiceFileUrlResult(value) {
    if (!('fileUrl' in value) || value['fileUrl'] === undefined)
        return false;
    return true;
}
export function GetInvoiceFileUrlResultFromJSON(json) {
    return GetInvoiceFileUrlResultFromJSONTyped(json, false);
}
export function GetInvoiceFileUrlResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'fileUrl': json['fileUrl'],
    };
}
export function GetInvoiceFileUrlResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'fileUrl': value['fileUrl'],
    };
}
